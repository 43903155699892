<template>
    <form @submit.prevent="saveSupplier" @error="onError">
        <mercur-card class="form form--supplier-edit px-4">
            <h2 class="font-weight-normal">Edit supplier</h2>

            <mercur-input :readonly="!isAllowedToEdit" v-model="supplierDetails.supplierName" :disabled="loading" required autofocus>
                Supplier name
            </mercur-input>

            <mercur-select :readonly="!isAllowedToEdit" v-model="supplierDetails.supplierStatus" :disabled="loading" :required="true" >
                <template slot="label">Status</template>
                <option v-for="(status, key) in supplierStatusses" :value="status.value" :key="key">{{status.title}}</option>
            </mercur-select>

            <mercur-input :readonly="!isAllowedToEdit" type="email" v-model="supplierDetails.emailAddress" :disabled="loading" required autofocus>
                Email
            </mercur-input>

            <mercur-input :readonly="!isAllowedToEdit" :disabled="loading" v-model="supplierDetails.websiteAddress">
                Web address - optional
            </mercur-input>

            <pretty-select
                placeholder="Fast Editor File Type"
                :options="['PDF', 'PNG']"
                label="Fast Editor File Type"
                v-model="supplierDetails.fastEditorFileType"
            ></pretty-select>

            <mercur-input :readonly="!isAllowedToEdit" :disabled="loading" v-model="supplierDetails.sourceId" required type="number" @input="validateInteger">
                SourceId
                <template slot="note">
                    <span class="text-secondary">SourceId MUST match the ID of the supplier on Presta's database</span>
                </template>
            </mercur-input>

            <mercur-input v-if="supplierDetails.settings.complaints" :readonly="!isAllowedToEdit" :disabled="loading" v-model.number="supplierDetails.settings.complaints.slaHours" type="number" step="1">
                Complaint SLA time
                <div slot="suffix">hours</div>
            </mercur-input>

            <span class="text-secondary">This can be overwritten on the office and facility level</span>

            <div class="text-right">
                <mercur-button v-if="isAllowedToEdit" type="submit" class="btn btn-raised btn-yellow text-uppercase" :disabled="loading">Save</mercur-button>
                <span v-else>Not allowed to save details</span>
            </div>
        </mercur-card>
    </form>
</template>

<script>
import CONFIG from '@root/config'
import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'SupplierEdit',
    components: { PrettySelect },
    computed: {
        supplier () {
            return this.$store.getters['suppliers/getSupplierById'](this.supplierId)
        },
        supplierStatusses () {
            return CONFIG.STATUSSES.SUPPLIERS.SUPPLIER
        },
        isAllowedToEdit () {
            return this.isAllowedTo('SupplierCentral/updateSupplier')
        },
    },
    data () {
        return {
            loading: true,
            supplierDetails: {
                supplierStatus: 'ACTIVE',
                supplierName: null,
                websiteAddress: null,
                emailAddress: null,
                supplierFactor: null,
                sourceId: null,
                settings: {},
            },
        }
    },

    watch: {
        supplier (value) {
            if (value) {
                this.loading = false
                this.supplierDetails = JSON.parse(JSON.stringify(value))
            }
        },
    },

    methods: {
        saveSupplier () {
            if (!this.isAllowedTo('SupplierCentral/updateSupplier')) {
                this.emitPermissionErrorUserIsNotAllowedTo('update supplier details')
                return
            }

            const data = {
                ...this.supplierDetails,
            }
            const url = CONFIG.API.ROUTES.SUPPLIERS.UPDATE.replace('{supplierId}', this.supplierId)
            this.addJob(url)
            this.loading = true
            this.$api.post(url, data).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Supplier saved successfully`,
                })
            }).catch((data) => {
                this.$root.$emit('notification:global', {
                    message: `Saving supplier failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
                this.loading = false
            })
        },
        onError (error) {
            this.$root.$emit('notification:global', {
                type: 'error',
                errors: error,
            })
        },
        validateInteger () {
            this.supplierDetails.sourceId = String(parseInt(this.supplierDetails.sourceId))
        },
    },
    created () {
        const url = CONFIG.API.ROUTES.SUPPLIERS.GET.replace('{supplierId}', this.supplierId)
        this.addJob(url)

        this.$api.get(url).then(({ data }) => {
            const supplierDetails = data.data

            this.$set(supplierDetails.settings, 'complaints', {
                slaHours: 48,
                ...(supplierDetails.settings.complaints || null),
            })

            this.$set(this, 'supplierDetails', supplierDetails)
        }).catch(data => {
            this.$root.$emit('notification:global', {
                message: `Getting current supplier details failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.loading = false
            this.finishJob(url)
        })
    },
}
</script>

<style scoped>
    .form--supplier-edit {
        width: 100vw;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
    }
</style>
